'use client';

import { ReactElement, useEffect, useState } from 'react';
import FadeUpAnimatedText from './FadeUpAnimatedText';

interface Title {
  firstPart: null | string,
  lastPart: null | string,
  animatedPart: null | string | ReactElement | ReactElement[],
}

const inititalTitle: Title = {
  firstPart: null,
  lastPart: null,
  animatedPart: null,
};

export const ANIMATED_TEXT_REGEX = '\\*\\*[^*]+\\*\\*';

export enum AnimationType {
  FULL,
  PARTIAL,
  NONE,
}

export type AnimatedTitleProps = { title: string, animationType?: AnimationType };

function getFormattedAnimatedTitle({ text, animationType, previousParsedTitle }: {
  text: AnimatedTitleProps['title'],
  animationType: AnimationType,
  previousParsedTitle?: Title,
}): Title {
  if (text) {
    const animatedTitleMatch = text.match(ANIMATED_TEXT_REGEX);
    if (animationType === AnimationType.FULL) {
      return {
        firstPart: null,
        lastPart: null,
        animatedPart: <FadeUpAnimatedText text={text} />,
      };
    } else if (animatedTitleMatch) {
      const divider = animatedTitleMatch.index;
      const textFirstPart = text.slice(0, divider);
      const textLastPart = divider !== undefined ? text.slice(divider + animatedTitleMatch[0].length) : '';
      return {
        firstPart: textFirstPart,
        lastPart: textLastPart,
        animatedPart: (
          animationType === AnimationType.NONE
            ? animatedTitleMatch[0].slice(2, animatedTitleMatch[0].length - 2)
            : <FadeUpAnimatedText text={animatedTitleMatch[0].slice(2, animatedTitleMatch[0].length - 2)} />
        ),
      };
    } else {
      return ({ ...(previousParsedTitle ?? inititalTitle), firstPart: text });
    }
  }

  return inititalTitle;
}

function AnimatedTitle({ title, animationType = AnimationType.PARTIAL }: AnimatedTitleProps) {
  const [formattedTitle, setFormattedTitle] = useState<Title>(getFormattedAnimatedTitle({
    text: title,
    animationType,
    previousParsedTitle: inititalTitle,
  }));

  useEffect(() => {
    setFormattedTitle(getFormattedAnimatedTitle({
      text: title,
      animationType,
      previousParsedTitle: inititalTitle,
    }));
  }, [title]);

  return (
    <>
      {formattedTitle.firstPart}
      {formattedTitle.animatedPart}
      {formattedTitle.lastPart}
    </>
  );
}

export default AnimatedTitle;
